import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as d3 from 'd3';

@Component({
  selector: 'codenteam-confidence-level',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confidence-level.component.html',
  styleUrl: './confidence-level.component.scss',
})
export class ConfidenceLevelComponent implements AfterViewInit {
  @ViewChild('confidence', { static: true }) confidence!: ElementRef;

  width = 60;
  height = 20;
  @Input()
  levels: number;
  @Input()
  confidenceLevel: number;
  colorRange = ['#CF3E3E', '#E8642D', '#E8872D', '#EDB60A', '#6EF140'];
  domain = [0.2, 0.4, 0.6, 0.8, 1];
  colorScale = d3
    .scaleLinear<string>()
    .domain(this.domain)
    .range(this.colorRange);

  public ngAfterViewInit() {
    this.draw();
  }
  draw() {
    const svg = d3
      .select(this.confidence.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    const rectangleWidth = 10;
    const rectangleHeight = 10;
    const cornerRadius = 2;
    const spacing = 2;
    const radius = rectangleHeight / 2;
    const confidence = this.confidenceLevel ? this.confidenceLevel + 1 : 0;

    if (confidence === 0) {
      svg
        .append('circle')
        .attr('cx', radius)
        .attr('cy', this.height / 2)
        .attr('r', radius)
        .attr('fill', this.colorScale(0.2));
    } else {
      for (let i = 1; i <= confidence; i++) {
        if (i === 1) {
          svg
            .append('circle')
            .attr('cx', radius)
            .attr('cy', this.height / 2)
            .attr('r', radius)
            .attr('fill', this.colorScale(0.2));

          svg
            .append('rect')
            .attr('x', radius)
            .attr('y', (this.height - rectangleHeight) / 2)
            .attr('width', rectangleWidth - radius)
            .attr('height', rectangleHeight)
            .attr('rx', cornerRadius)
            .attr('ry', cornerRadius)
            .attr('fill', this.colorScale(0.2));
        } else if (i === confidence) {
          svg
            .append('rect')
            .attr('x', (i - 1) * (rectangleWidth + spacing))
            .attr('y', (this.height - rectangleHeight) / 2)
            .attr('width', rectangleWidth - radius)
            .attr('height', rectangleHeight)
            .attr('rx', cornerRadius)
            .attr('ry', cornerRadius)
            .attr('fill', this.colorScale(i / this.levels));

          svg
            .append('circle')
            .attr('cx', i * (rectangleWidth + spacing) - 1.4 * radius) // Center of the circle
            .attr('cy', this.height / 2) // Center vertically
            .attr('r', radius) // Radius of the semi-circle
            .attr('fill', this.colorScale(i / this.levels));
        } else {
          svg
            .append('rect')
            .attr('x', (i - 1) * (rectangleWidth + spacing))
            .attr('y', (this.height - rectangleHeight) / 2)
            .attr('width', rectangleWidth)
            .attr('height', rectangleHeight)
            .attr('rx', cornerRadius)
            .attr('ry', cornerRadius)
            .attr('fill', this.colorScale(i / this.levels));
        }
      }
    }
  }
}
